import React from "react";
import "./App.css";
import SysbinLogo from "./assets/Sysbin.png"; // Import your logo

function App() {
  return (
    <div className="coming-soon-container">
      <header className="header">
        {/* Replace the text with the logo */}
        <img src={SysbinLogo} alt="Sysbin Logo" className="logo" />
      </header>
      <main className="content">
        <div className="text-section">
          <h2 className="main-heading">
            Ready to make <br /> your dream site?
          </h2>
          <div className="buttons">
            <button className="btn stay-tuned">
              Stay Tuned, Good Things are Coming
            </button>
            <button className="btn get-ready">
              Get READY <span className="arrow">→ </span>
            </button>
          </div>
        </div>
        <div className="animation-section">
          <div className="glowing-text">
            COMING <br /> SOON
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
